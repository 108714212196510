import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import logo from "../assets/css/images/bloglogo.png"

export default () => (
  <Layout isHome={false} pageId="blogpage" logo={logo}>
    <SEO title="404: Not found" />

    <div id="content">
      <ul id="content_article" className="four">
        <li>
          <h1>Not found</h1>
          <div id="contactpage">
            <div id="content">
              <div id="contact_form">
                <p className="contact-intro">
                  We were not able to find the page you requested.
                </p>

                <form method="get" action="/">
                  <div className="setone">
                    <label htmlFor="subject" className="required">
                      Keyword :
                    </label>{" "}
                    <input type="text" name="s" />
                  </div>
                  <div className="settwo">
                    <input type="submit" value="Search" />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <ul id="content_category">
        <li id="recent-posts-2" className="widget widget_recent_entries">
          <div className="widget_content">
            {" "}
            <h2 className="widget-title">Recent Posts</h2>{" "}
            <ul>
              <li>
                <a href="http://getfresh.ro/suspendisse-aliquam-tellus-et-magna-dictum/">
                  Suspendisse aliquam tellus et magna dictum
                </a>
              </li>
              <li>
                <a href="http://getfresh.ro/vestibulum-erat-est-feugiat-et-imperdiet-sit-amet/">
                  Vestibulum erat est, feugiat et imperdiet sit amet
                </a>
              </li>
              <li>
                <a href="http://getfresh.ro/project-5/">Project 5</a>
              </li>
              <li>
                <a href="http://getfresh.ro/project-4/">Project 4</a>
              </li>
              <li>
                <a href="http://getfresh.ro/project-3/">Project 3</a>
              </li>
            </ul>
          </div>
        </li>{" "}
        <li id="categories-2" className="widget widget_categories">
          <div className="widget_content">
            <h2 className="widget-title">Categories</h2>{" "}
            <ul>
              <li className="cat-item cat-item-1">
                <a href="http://getfresh.ro/category/blog/">Blog</a>
              </li>
              <li className="cat-item cat-item-6">
                <a href="http://getfresh.ro/category/featured/">Featured</a>
              </li>
              <li className="cat-item cat-item-5">
                <a href="http://getfresh.ro/category/folio/">Folio</a>
              </li>
            </ul>
          </div>
        </li>
        <li id="text-2" className="widget widget_text">
          <div className="widget_content">
            <h2 className="widget-title">Services</h2>{" "}
            <div className="textwidget">
              <ul>
                <li>
                  <a href="/">identity management</a>
                </li>
                <li>
                  <a href="/">UX</a>
                </li>
              </ul>
            </div>
          </div>
        </li>{" "}
      </ul>
    </div>
  </Layout>
)
